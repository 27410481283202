import React from 'react';
import { ProductModel } from '@/models/Product.model';
import ProductImageBase64 from '../common/ProductImageBase64';
import { useRouter } from 'next/router';
import BundleQnt from './BundleQnt';

interface Props {
  product: ProductModel;
  isBundleImage?: boolean;
}

export default function ProductCardBundle({ product, isBundleImage }: Props) {
  const router = useRouter();
  return (
    <a href={`${product.slugname}`}>
      <div className="px-1 md:px-4 pt-1 md:pt-2 pb-1 bg-white hover:shadow-md">
        <div className="relative">
          <ProductImageBase64
            pimImage={product.pimImage}
            base64={product.base64}
            alt={product.name}
          />
          <BundleQnt qnt={product.qntBundle} />
        </div>
        {isBundleImage ? (
          <></>
        ) : (
          <div className={`xs:hidden lg:block`}>
            <h2 className="leading-3 h-9 overflow-hidden mb-0.5">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  router.push(`${router.locale}/brand/${product.brand}`);
                }}
                className="font-bold text-xs leading-3 hover:underline"
              >
                {product.brand}
              </span>
              <span className="text-xs leading-3 mx-1 hover:underline">
                {product.name}
              </span>
            </h2>
          </div>
        )}

        <div
          className={
            isBundleImage
              ? 'leading-3 mt-1 text-center '
              : 'leading-3 mt-1 text-center xs:hidden lg:block'
          }
        >
          <span className="text-sm text-redPrice font-bold">฿</span>
          <span className="text-redPrice font-bold text-3xl">{`${product.price}`}</span>
          <span className="text-xs text-grayDark xs:hidden lg:inline">
            /{product.prUname}
          </span>
        </div>
      </div>
    </a>
  );
}
