import { Grid } from '@mui/material';
import React, { useState } from 'react';
import styles from '../../styles/bundle.module.css';
import { useTranslation } from 'next-i18next';
import AddToCartButton from '../ProductCard/AddToCartButton';
import { SkuWithQty } from '@/models/AddToCart.model';

interface Props {
  allpercent: string;
  allplppprice: string;
  product: string;
  skubundle: string;
  skuQtyList?: SkuWithQty[];
  setId?: string;
  allprice: string;
  allpricesave: string;
}
export default function BundleChildMobile({
  allpercent,
  allplppprice,
  product,
  skubundle,
  setId,
  skuQtyList,
  allprice,
  allpricesave,
}: Props) {
  const [isChildShow, setChildIsShow] = useState(false);
  const toggleChildBundle = () => {
    setChildIsShow((prevIsShow) => !prevIsShow);
  };
  const { t } = useTranslation('product');

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: {
          xs: 'block',
          lg: 'none',
          borderTop: '1px solid rgba(0, 0, 0, .1)',
          borderBottom: '1px solid rgba(0, 0, 0, .1)',
          padding: '10px',
        },
      }}
    >
      <Grid
        container
        onClick={() => {
          toggleChildBundle();
        }}
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, .1)',
          paddingBottom: '10px',
        }}
      >
        <Grid item xs={2}>
          <div className={`${styles.disccirclebundle}`}>
            {allpercent}
            <span style={{ fontSize: '10px' }}>%</span>
            <br />
            <span style={{ fontSize: '15px' }}>OFF</span>
          </div>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            textAlign: 'center',
          }}
        >
          <span className="text-xs pr-1">{t('thewholesetischeaper')}</span>
          <span className={`text-xs text-redPrice pr-1`}>{t('onlyleft')}</span>
          <span className="text-redPrice">
            <span className={`text-lg font-bold`} style={{ lineHeight: '1em' }}>
              {allplppprice}
            </span>
          </span>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            textAlign: 'center',
          }}
        >
          <i
            className={`bi bi-chevron-${
              isChildShow ? 'up' : 'down'
            } text-xs h-3 leading-3 pr-2`}
          ></i>
        </Grid>
      </Grid>
      {isChildShow ? (
        <>
          {product && JSON.parse(product).length > 0 ? (
            <>
              {JSON.parse(product).map((product: any, i: number) => {
                return (
                  <Grid
                    container
                    alignItems="center"
                    key={i}
                    sx={{
                      borderBottom: '1px solid rgba(0, 0, 0, .1)',
                      marginTop: 1,
                    }}
                  >
                    <Grid item xs={1}>
                      <p className="text-xs">
                        <i className="bi bi-check2-square"></i>
                      </p>
                    </Grid>
                    <Grid item xs={11}>
                      <p className="text-xs">{product.name}</p>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <></>
          )}
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: 1,
              paddingBottom: 1,
            }}
          >
            <Grid item xs={12} className="text-center text-xs">
              <span>
                {t('refund_price')} {allprice}
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              className="text-center text-xs font-bold"
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, .1)',
                marginTop: 0,
                paddingBottom: 1,
              }}
            >
              <span>
                {t('thewholesetischeaper')} {allpricesave}
              </span>
            </Grid>
            <Grid item xs={12} className="text-center text-xs">
              <span>
                {t('onlyleft')}{' '}
                <span className="text-redPrice font-bold text-xl">
                  {allplppprice}
                </span>
              </span>
            </Grid>
            <Grid item xs={12} className="text-center">
              <AddToCartButton
                sku={skubundle}
                setId={setId}
                skuQtyList={skuQtyList}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
}
