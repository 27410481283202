import React, { useRef } from 'react';
import ProductCard from '../ProductCard';
import { ProductModel } from '@/models/Product.model';
import { Box } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../../styles/slickProductCard.module.css';
import heroBannerClass from '@/styles/herobanner.module.css';
import ProductCardBundle from '../pdp/ProductCardBundle';

interface Props {
  products: ProductModel[] | undefined;
  title?: string;
  slidesToShow?: number;
  slidesToScroll?: number;
  slidesToShowMobile?: number;
  slidesToScrollMobile?: number;
  isBundle?: boolean;
  autoplay?: boolean;
  isNotInfinite?: boolean;
  isDotsMobile?: boolean;
  isBundleImage?: boolean;
  bgColor?: string | null | undefined;
  hideBottomAction?: boolean;
}

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonNext}`}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: '-1rem',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-right"></i>
        </Box>
      </Box>
    </>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonPrevious}`}
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: '50%',
          transform: 'translateY(-50%)',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-left"></i>
        </Box>
      </Box>
    </>
  );
}

export default function SlickProductCard({
  products,
  title,
  slidesToShow,
  slidesToScroll,
  slidesToShowMobile,
  slidesToScrollMobile,
  isBundle,
  autoplay,
  isNotInfinite,
  isDotsMobile,
  isBundleImage,
  bgColor,
  hideBottomAction,
}: Props) {
  const sliderRef = useRef<Slider>(null);
  let IsInfinite: boolean = false;
  IsInfinite = products && products.length > 4 ? true : false;
  const settings = {
    dots: false,
    infinite: isNotInfinite ? false : IsInfinite,
    autoplay: autoplay ? true : false,
    speed: 500,
    slidesToShow: slidesToShow ? slidesToShow : 4,
    slidesToScroll: slidesToScroll ? slidesToScroll : 1,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: isDotsMobile,
          slidesToShow: slidesToShowMobile ? slidesToShowMobile : 2,
          slidesToScroll: slidesToScrollMobile ? slidesToScrollMobile : 1,
          nextArrow: isDotsMobile ? <></> : <SampleNextArrow />,
          prevArrow: isDotsMobile ? <></> : <SamplePrevArrow />,
          dotsClass: `slick-dots ${styles.dots}`,
        },
      },
    ],
  };

  return (
    <>
      {products && products.length > 0 ? (
        <>
          {title ? (
            <h2 className="text-2xl font-bold text-center text-primary">
              {title}
            </h2>
          ) : (
            ''
          )}
          <div style={{ backgroundColor: bgColor ? bgColor : '' }}>
            <Slider ref={sliderRef} {...settings}>
              {products.map((product, index) => (
                <div
                  key={`${product.sku}${index}`}
                  className={`${
                    isBundle ? styles.navProdBundle : styles.navProd
                  } relative`}
                >
                  {isBundle ? (
                    <>
                      <ProductCardBundle
                        product={product}
                        isBundleImage={isBundleImage}
                      />
                      {index + 1 == products.length ? (
                        <></>
                      ) : (
                        <span
                          className={
                            isBundleImage
                              ? `${styles.plusBundleImage} absolute`
                              : `${styles.plusBundle} absolute`
                          }
                        >
                          +
                        </span>
                      )}
                    </>
                  ) : (
                    <ProductCard
                      listName={`slide_${title ?? 'product'}`}
                      product={product}
                      hideBottomAction={hideBottomAction}
                    />
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
