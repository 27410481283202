import { BundleModel, ProductBundle } from '@/models/Bundle.model';
import { ProductModelConverter } from '@/models/Product.model';
import React, { useEffect, useState } from 'react';
import SlickProductCard from '../common/SlickProductCard';
import { Button, Grid } from '@mui/material';
import styles from '../../styles/bundle.module.css';
import BundleChildMobile from './BundleChildMobile';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import AddToCartButton from '../ProductCard/AddToCartButton';
import { SkuWithQty } from '@/models/AddToCart.model';
interface Props {
  data: BundleModel;
}
export default function BundleCard({ data }: Props) {
  const { t } = useTranslation('product');
  const router = useRouter();
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    setIsShow(false);
  }, [router.asPath]);

  const toggleBundle = () => {
    setIsShow((prevIsShow) => !prevIsShow);
  };
  let slickComponents: any = []; // Create an array to store slick components
  if (data && data.products && data.products.length > 0) {
    data.products.forEach((product, index) => {
      const skuQtyList: SkuWithQty[] = [];
      let allpercent: string = '';
      let allprice: string = '';
      let allplppprice: string = '';
      let proEnddate: string = '';
      let setid: string = '';
      let skubundle: string = '';
      let allpricesave: string = '';
      let convertedProducts: any = '';
      if (Array.isArray(product)) {
        product.forEach((element: ProductBundle) => {
          skuQtyList.push({
            sku: element.skc?.toString() ?? '',
            qty: element.qntBundle ?? 1,
          });
        });
        allpercent = product[0].allpercent;
        allprice = product[0].allprice;
        allplppprice = product[0].allplppprice;
        proEnddate = product[0].proEnddate;
        setid = product[0].setid;
        skubundle = product[0].skc;
        allpricesave = product[0].allpricesave;
        convertedProducts = product?.map((product) =>
          ProductModelConverter.fromJson(product),
        );
      } else {
        console.log('product is not an array');
      }
      const slick = (
        <div key={`${index}`}>
          {isShow || index == 0 ? (
            <Grid container sx={{ marginBottom: '20px' }}>
              <Grid item lg={9} xs={12}>
                <SlickProductCard
                  products={convertedProducts}
                  title={''}
                  slidesToShow={4}
                  slidesToScroll={1}
                  slidesToShowMobile={4}
                  slidesToScrollMobile={1}
                  isBundle={true}
                  isNotInfinite={true}
                  isDotsMobile={true}
                />
              </Grid>
              <Grid
                sx={{
                  display: { xs: 'none', lg: 'block' },
                }}
                item
                lg={3}
                className="text-center"
              >
                <span className="text-md mt-2 text-grayDark">
                  {t('allprice')} {allprice}
                </span>
                <div className={`${styles.discimage}`}>
                  <div className={`${styles.discribbonbundle} mx-auto`}>
                    <div className={`${styles.morecheap}`}>
                      <span>{t('thewholesetischeaper')}</span>
                    </div>
                    <div
                      className={`${styles.discpercent} mx-auto text-redPrice`}
                    >
                      {allpercent}
                      <span className="text-2xl">%</span>
                    </div>
                  </div>
                  <div className={`${styles.lessStock} mx-auto`}>
                    <span
                      className={`${styles.lessStockText} text-md text-redPrice`}
                    >
                      {t('onlyleft')}
                    </span>
                    <span className="text-redPrice">
                      ฿
                      <span
                        className={`text-3xl`}
                        style={{ lineHeight: '1em' }}
                      >
                        {allplppprice}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="text-xs">
                  <span className="text-grayDark">
                    {t('thispriceisvalidunti')} {proEnddate} {t('only')}
                  </span>
                </div>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  className={`max-width-container text-center py-2`}
                >
                  <Grid
                    sx={{
                      display: { xs: 'none', lg: 'block' },
                    }}
                    item
                    lg={4}
                    className="text-center"
                  >
                    <AddToCartButton
                      sku={skubundle}
                      setId={setid}
                      skuQtyList={skuQtyList}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: { xs: 'none', lg: 'block' },
                }}
                item
                lg={3}
                className="text-center"
              ></Grid>
              <BundleChildMobile
                allpercent={`${allpercent}`}
                allplppprice={`${allplppprice}`}
                product={JSON.stringify(product)}
                skubundle={`${skubundle}`}
                setId={setid}
                skuQtyList={skuQtyList}
                allprice={`${allprice}`}
                allpricesave={`${allpricesave}`}
              />
            </Grid>
          ) : (
            <></>
          )}
        </div>
      );
      slickComponents.push(slick); // Add the slick component to the array
    });
  }
  return (
    <>
      {data && data.products && data.products.length > 0 ? (
        <>
          <h2 className="text-2xl font-bold text-center text-primary">
            {t('buythebestsetataspec')}
          </h2>
          {slickComponents}
          {data && data.products && data.products.length > 1 && (
            <Grid
              container
              sx={{ marginBottom: '20px' }}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={11}>
                <Button
                  className="text-center bg-primary btnfilter rounded-lg"
                  variant="outlined"
                  sx={{
                    color: 'white',
                    borderColor: '#4E1F66',
                    background: '#4E1F66',
                    width: '100%',
                    '&:hover': {
                      background: '#4E1F66',
                    },
                  }}
                  onClick={toggleBundle}
                >
                  <i
                    className={`bi bi-chevron-${
                      isShow ? 'up' : 'down'
                    } text-xs h-3 leading-3 pr-2`}
                  ></i>
                  {isShow ? t('close') : t('seeothergreatdeals')}
                  <i
                    className={`bi bi-chevron-${
                      isShow ? 'up' : 'down'
                    } text-xs h-3 leading-3 pl-2`}
                  ></i>
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
