import React from 'react';
import SlickStyles from '../../styles/bundle.module.css';
import { Typography } from '@mui/material';

interface Props {
  qnt?: number;
}
export default function BundleQnt({ qnt }: Props) {
  return qnt ? (
    <div className={`${SlickStyles.BundleQnt}`}>
      <span className={`${SlickStyles.BundleQntBg}`}>
        <Typography
          sx={{
            fontSize: { xs: '0.5em', lg: '1em' },
          }}
        >
          x
        </Typography>
        <span className={`${SlickStyles.BundleQntText}`}>{qnt}</span>
      </span>
    </div>
  ) : null;
}
